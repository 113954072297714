import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export const Poker = () => {
  return (
    <header id="poker" className="p-4 rounded-5 pokerBackgroundImg">
      <div className="container">
        <div className="row">
          {/* Image on the left */}
          <div className="col-md-4">
            <img src="./poker.jpg" className="img-fluid rounded" alt="Poker Game" />
          </div>

          {/* Title and Text on the top-right */}
          <div className="col-md-8 d-flex flex-column justify-content-start align-items-end text-end">
            <h1 className="display-5 fw-bold">Poker</h1>
            <p>
              🍻 Enjoy the thrill of the game while sipping on your favorite
              drinks from our well-stocked bar. Our friendly staff is here to
              ensure you have everything you need for an unforgettable poker
              experience.
            </p>
            <div className="text-center">
              <h2>Monthly Poker Tournament information available at <strong className="h4">
                <a
                  href="https://www.facebook.com/groups/204943466202129"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Spokane Poker's Facebook page
                </a>
              </strong></h2>
              <div className="fw-bold text-center">Sign-Ups:</div>
              <div className="text-center m-1">The bar will have sign-up sheets available.</div>
              <div className="text-center">
                Sign up before noon on the day of the event to get an extra 3k
                in chips.
              </div>
              
            </div>
          </div>
        </div>

        {/* Poker Schedule */}
        <div className="text-center mt-4">
          <hr />
          <button
            className="btn custom-btn mb-3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#pokerSchedule"
            aria-expanded="false"
            aria-controls="pokerSchedule"
          >
            Poker Schedule
          </button>

          <div className="collapse" id="pokerSchedule">
            <div>
              <div className="fw-bold m-1">Tuesday & Saturday:</div>
              <p>@ 7:15 PM</p>
              <p>$20 buy-in, $10 add-on, 3k on-time bonus.</p>
            </div>
            <div>
              <div className="fw-bold m-1">Sunday:</div>
              <p>@ 7:00 PM</p>
              <p>$25 buy-in, $5 bounty, 1 optional add-on per entry.</p>
              <p>2-10 live game to follow - NO RAKE.</p>
            </div>
          </div>

          <div className="fw-bold m-2">THERE IS NO HOUSE FEE</div>
          <div className="fw-bold m-1">ALL MONEY IS PAID OUT</div>
        </div>
      </div>
    </header>
  );
};
