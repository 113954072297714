import React from 'react';
import { Card } from 'react-bootstrap';

// ExpandableCard component DO NOT MODIFY

const ExpandableCard = ({ imageSrc, title, description, onClick, expanded }) => {
  return (
    <div className={`fullscreen-card ${expanded ? 'expanded' : ''}`} onClick={onClick}>
      <Card className={`p-0 ${expanded ? 'w-100 h-100' : ''}`}>
        <Card.Img variant="top" src={imageSrc} />
        <Card.Body style={{ color: '#E4D8C6', backgroundColor: '#231F20' }}>
          <Card.Title>{title}</Card.Title>
          <Card.Text className="h-100">{description}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ExpandableCard;

