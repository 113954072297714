import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export const Karaoke = () => {
  return (
    <header id="karaoke" className="p-4 my-2 rounded-5 karaokeImg">
      <div className="container">
        <div className="row">
          {/* Image on the left */}
          <div className="col-md-4">
            <img
              src="./singer1.jpg"
              className="img-fluid rounded"
              alt="Singer"
            />
          </div>

          {/* Title and Text on the top-right */}
          <div className="col-md-8 d-flex flex-column justify-content-start align-items-end text-end">
            <h1 className="display-5 fw-bold">Karaoke</h1>
            <p className="mt-2">
              🎤🎉 Get ready for the ultimate Karaoke night at PJ's Pub! 🎶
              Unleash your inner rockstar and join us for a night of
              unforgettable performances, lively tunes, and fantastic company.
              Whether you're a seasoned crooner or a first-time singer, this
              is your chance to shine!
            </p>
          </div>
        </div>

        {/* Karaoke Hours */}
        <div className="text-center mt-4">
          <hr />
          <div className="fw-bold">Karaoke Hours:</div>
          <div>Friday & Saturday @ 8 pm</div>
          <div>Sunday, Wednesday & Thursday @ 9 pm</div>
        </div>
      </div>
    </header>
  );
};
