// CardDisplay.jsx

import React, { useState } from "react";
import ExpandableCard from "../ExpandableCard"; // Adjust the path based on your file structure

const CardDisplay = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (cardIndex) => {
    setSelectedCard(selectedCard === cardIndex ? null : cardIndex);
  };

  const cards = [
    {
      imageSrc: "Static-Menu-1-01-2024.png",
      title: "Menu Page 1",
      description:
        "Offering starters like deep-fried pickles and mozzarella sticks, quesadillas, assorted baskets, and a choice of salads or wraps.",
    },
    {
      imageSrc: "Static-Menu-01-2024.png",
      title: "Menu Page 2",
      description:
        "Features gourmet burgers, sandwiches, and snacks with an array of dips, catering to hearty appetites with a taste for American classics.",
    },
    {
      imageSrc: "Alternative-Menu-2024.png",
      title: "Alternative Eats",
      description:
        "A rotaiting menu of comforting eats that you can pair with our inventive bar drinks. Come check out what we have going on." ,
    },
    {
      imageSrc: "112024SpecialPizza.jpg",
      title: "Specials",
      description:
        "A rotaiting menu of comforting eats that you can pair with our inventive bar drinks. Come check out what we have going on." ,
    },

    
  ];

  return (

    <div className="container my-4">
      <h1 id="menu" className="text-center mb-4">Menus</h1>
      <div className="d-flex flex-column align-items-center justify-content-center">
        {/* Display selected card if there is one */}
        {selectedCard !== null && (
          <div key={selectedCard} className="col mb-4">
            <ExpandableCard
              imageSrc={cards[selectedCard].imageSrc}
              title={cards[selectedCard].title}
              description={cards[selectedCard].description}
              onClick={() => handleCardClick(selectedCard)}
              expanded={true}
            />
          </div>
          
        )}

        {/* Display unselected cards in a Bootstrap grid */}
        <div className="card-container row">
          {cards.map((card, index) => (
            <div key={index} className={`col-md-3 mb-4 ${selectedCard !== null ? "d-none" : ""}`}>
              <ExpandableCard
                imageSrc={card.imageSrc}
                title={card.title}
                description={card.description}
                onClick={() => handleCardClick(index)}
                expanded={selectedCard === index}
              />
            </div>
          ))}
        </div>
      </div>
    </div>

  );
};

export default CardDisplay;
